import logo1 from "../../images/logo1.png";
import validate from "../../functions/validations/bookings";
import { useRef } from "react";
import { useState } from "react";
import Spinner from "./spinner";
import http from "../../functions/http";
import { useDispatch } from "react-redux";
import alert from "../../store/reducers/alert-reducer";

const BookATableModal = () => {
  let dispatch = useDispatch();

  const [submitting, set_submitting] = useState(false);

  const nameInput = useRef();
  const emailInput = useRef();
  const phoneInput = useRef();
  const dateInput = useRef();
  const timeInput = useRef();
  const seatsInput = useRef();
  const descriptionInput = useRef();

  const [nameErr, set_nameErr] = useState("");
  const [emailErr, set_emailErr] = useState("");
  const [phoneErr, set_phoneErr] = useState("");
  const [dateErr, set_dateErr] = useState("");
  const [timeErr, set_timeErr] = useState("");
  const [seatsErr, set_seatsErr] = useState("");
  const [descriptionErr, set_descriptionErr] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    clearErrs();

    if (submitting) return;

    set_submitting(true);

    let err = null;

    const name = validate._name(nameInput.current.value);

    const email = validate._email(emailInput.current.value);
    const phone = validate._phone(phoneInput.current.value);
    const date = validate._date(dateInput.current.value);
    const time = validate._time(timeInput.current.value);
    const description = validate._description(descriptionInput.current.value);
    const seats = validate._seats(seatsInput.current.value);

    if (name.err) {
      err = 1;
      set_nameErr(name.err);
    }
    if (email.err) {
      err = 1;
      set_emailErr(email.err);
    }
    if (phone.err) {
      err = 1;
      set_phoneErr(phone.err);
    }
    if (date.err) {
      err = 1;
      set_dateErr(date.err);
    }
    if (time.err) {
      err = 1;
      set_timeErr(time.err);
    }
    if (description.err) {
      err = 1;
      set_descriptionErr(description.err);
    }
    if (seats.err) {
      err = 1;
      set_seatsErr(seats.err);
    }

    if (err) {
      set_submitting(false);
      return;
    }

    const dataToSubmit = { name, email, phone, date, time, description, seats };

    let res = await http.post("/api/bookings", dataToSubmit);

    res = res.data;
    if (res && res.id) {
      set_submitting(false);
      dispatch(
        alert.load({
          message: `Booking request has been succesfully submitted. You will be informed about the confirmation by the email ( ${email} ) you provided!`,
          heading: "Success!",
          type: "success",
        })
      );
      clearValues();
    }
  };

  // clear errs
  function clearErrs() {
    set_nameErr("");
    set_emailErr("");
    set_phoneErr("");

    set_dateErr("");
    set_timeErr("");
    set_descriptionErr("");
    set_seatsErr("");
  }

  // clear values
  function clearValues() {
    nameInput.current.value = "";
    emailInput.current.value = "";
    phoneInput.current.value = "";
    dateInput.current.value = "";
    timeInput.current.value = "";
    seatsInput.current.value = "";
    descriptionInput.current.value = "";
  }
  if (
    window.dat &&
    window.dat.toggles &&
    parseInt(window.dat.toggles["bookings"].status) === 0
  ) {
  }
  return (
    <div className="modal fade" id="bookATableModal" tabIndex="-1">
      <div
        className="modal-dialog m-0 mx-auto h-100 w-100"
        style={{ maxWidth: "1600px" }}
      >
        <div
          className="container-fluid modal-content  h-100"
          style={{ backgroundColor: "unset" }}
        >
          <div className="row h-100">
            <div className="col-md-2 d-flex bg-white bg-opacity-10 justify-content-center align-items-center">
              <div className="py-5 text-center">
                <img src={logo1} className="w-50" alt="delhi tandoori logo" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="py-5 text-white">
                <h1
                  className="text-center lh-1 mb-5"
                  style={{ fontFamily: "serif" }}
                >
                  <i style={{ fontSize: "1.3em" }}>Table </i>
                  <br />
                  Booking
                </h1>

                {window.dat &&
                window.dat.toggles &&
                parseInt(window.dat.toggles["bookings"].status) === 0 ? (
                  <div className="p-3">
                    <h5 className=" alert alert-danger mb-0">
                      Table bookings will be availble soon!
                    </h5>
                    <p className="text-danger">
                      This service is temporarily not available.
                    </p>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit} className="row px-3 px-md-5">
                    {/* name */}
                    <div className="col-md-6 col-lg-4 mb-5">
                      <label className="fs-5" htmlFor="name">
                        Name *
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="form-control custom px-0"
                        ref={nameInput}
                        onChange={() => {
                          set_nameErr("");
                        }}
                      />
                      <div style={{ color: "pink" }}>
                        {nameErr ? (
                          <span className="p-1 d-block">{nameErr}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* email */}
                    <div className="col-md-6 col-lg-4 mb-5">
                      <label className="fs-5" htmlFor="email">
                        Email *
                      </label>
                      <input
                        onChange={() => {
                          set_emailErr("");
                        }}
                        id="email"
                        type="email"
                        className="form-control custom px-0"
                        ref={emailInput}
                      />
                      <div style={{ color: "pink" }}>
                        {emailErr ? (
                          <span className="p-1 d-block">{emailErr}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* phone */}
                    <div className="col-md-6 col-lg-4 mb-5">
                      <label className="fs-5" htmlFor="phone">
                        Phone *
                      </label>
                      <input
                        onChange={() => {
                          set_phoneErr("");
                        }}
                        id="phone"
                        type="tel"
                        className="form-control custom px-0"
                        ref={phoneInput}
                      />
                      <div style={{ color: "pink" }}>
                        {phoneErr ? (
                          <span className="p-1 d-block">{phoneErr}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* date */}
                    <div className="col-md-6 col-lg-4 mb-5">
                      <label className="fs-5" htmlFor="date">
                        Date *
                      </label>
                      <input
                        onChange={() => {
                          set_dateErr("");
                        }}
                        id="date"
                        type="date"
                        className="form-control white-date-picker custom px-0"
                        ref={dateInput}
                      />
                      <div style={{ color: "pink" }}>
                        {dateErr ? (
                          <span className="p-1 d-block">{dateErr}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* time */}
                    <div className="col-md-6 col-lg-4 mb-5">
                      <label className="fs-5" htmlFor="time">
                        Time *{" "}
                        <small className="fs-6">{"(15:00 to 21:15)"}</small>
                      </label>
                      <input
                        onChange={() => {
                          set_timeErr("");
                        }}
                        id="time"
                        type="time"
                        style={{ colorScheme: "dark" }}
                        className="form-control custom px-0"
                        ref={timeInput}
                        min={"15:00"}
                        max={"21:15"}
                      />
                      <div>
                        <small className="fs-6">
                          sitting time is 15:00 to 22:00
                        </small>
                      </div>
                      <div style={{ color: "pink" }}>
                        {timeErr ? (
                          <span className="p-1 d-block">{timeErr}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* seats */}
                    <div className="col-md-6 col-lg-4 mb-5">
                      <label className="fs-5" htmlFor="seats">
                        Seats *
                      </label>
                      <input
                        onInput={(e) => {
                          set_seatsErr("");
                          const el = e.target;
                          const value = parseInt(el.value);
                          if (value < 0) el.value = 0;
                          if (value > 28) el.value = 28;
                        }}
                        min={1}
                        id="seats"
                        type="number"
                        className="form-control custom px-0"
                        ref={seatsInput}
                      />
                      <div style={{ color: "pink" }}>
                        {seatsErr ? (
                          <span className="p-1 d-block">{seatsErr}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* description */}
                    <div className="col-12 mb-5">
                      <label className="fs-5" htmlFor="special-requests">
                        Special Requests
                      </label>
                      <input
                        onChange={() => {
                          set_descriptionErr("");
                        }}
                        id="special-requests"
                        type="text"
                        className="form-control custom px-0"
                        ref={descriptionInput}
                      />
                      <div style={{ color: "pink" }}>
                        {descriptionErr ? (
                          <span className="p-1 d-block">{descriptionErr}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="text-center">
                      {submitting ? (
                        <div
                          className="bg-light btn"
                          style={{ minWidth: "180px" }}
                        >
                          <Spinner color={"#f24141"} text={"submitting..."} />
                        </div>
                      ) : (
                        <button
                          className="btn btn-light  "
                          style={{ minWidth: "180px" }}
                        >
                          Book Now
                        </button>
                      )}
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="col-md-2 bg-white bg-opacity-10">
              <div className="p-5 text-center">
                <button
                  style={{ right: "30px", top: "30px", transition: ".3s" }}
                  type="button"
                  onClick={() => {
                    clearValues();
                    clearErrs();
                  }}
                  className="btn-close bg-white fs-4  position-sm-fixed"
                  data-bs-dismiss="modal"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookATableModal;
