import Logo from "./components/logo";
import logo1 from "../images/logo1.png";
import Footer from "./components/footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validate from "../functions/validations/order";

import http from "../functions/http";
import alert from "../store/reducers/alert-reducer";
import Alert from "./components/alert";
import cart from "../store/reducers/cart";

const ConfirmOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [submitting, set_submitting] = useState(0);

  const nameInput = useRef();
  const phoneInput = useRef();
  const emailInput = useRef();
  const descriptionInput = useRef();

  const [nameErr, set_nameErr] = useState("");
  const [phoneErr, set_phoneErr] = useState("");
  const [emailErr, set_emailErr] = useState("");
  const [descriptionErr, set_descriptionErr] = useState("");

  const cartItems = useSelector((state) => state.cart.items);
  useEffect(() => {
    if (cartItems.length < 1) {
      navigate("/take-away/start");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [cartItems, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitting) return;

    let err = null;

    const name = validate._name(nameInput.current.value);
    const email = validate._email(emailInput.current.value);
    const phone = validate._phone(phoneInput.current.value);
    const description = validate._description(descriptionInput.current.value);

    if (name.err) {
      err = 1;
      set_nameErr(name.err);
    }

    if (email.err) {
      err = 1;
      set_emailErr(email.err);
    }
    if (phone.err) {
      err = 1;
      set_phoneErr(phone.err);
    }
    if (description.err) {
      err = 1;
      set_descriptionErr(description.err);
    }
    if (err) return;

    const dataToSubmit = {
      name,
      email,
      phone,
      description,
      orderItems: cartItems,
    };
    set_submitting(1);

    const res = await http.post("/api/place-order", dataToSubmit);

    if (res.data.id) {
      set_submitting(0);

      const totalItems = cartItems.reduce((a, b) => a + b.quantity, 0);
      const total = cartItems.reduce((a, b) => a + b.price * b.quantity, 0);
      let lis = "";

      cartItems.forEach((item) => {
        lis += `<li class=" mb-4 d-flex">
        <div class="me-auto">
          <div>
            ${
              item.pName
            } <span style="paddingBottom: 2px; backgroundColor: #CDDC39" class="px-2">
              ${item.quantity} x
            </span>
          </div> 
          <div style="font-size:0.7em"
            class="text-secondary">
            ${item.psName || ""}
          </div>
        </div>
        <div>
          kr. ${item.price}
        </div>
      </li>`;
      });

      dispatch(
        alert.load({
          detail: `
          <div class="pb-3" style='border: 1px solid #e4e4e4;backdrop-filter: blur(6px);background-color:#f0f8ffd4;'>
          <h5 class='p-3' style='background-color: #d6f8c7;color: #104c10;'>Order# ${
            res.data.id
          } </h5>
          ${
            dataToSubmit.description
              ? '<p class="px-3 bg-light border py-1 m-0">' +
                dataToSubmit.description +
                "</p>"
              : ""
          }
            <ul style="max-height:32vh" class="list-unstyled my-3 custom-scroll-2 px-3 overflow-auto fw-semibold">
               ${lis}
            </ul>
              <div class="px-3">
                 <div style="background: #605c5f; color: white; padding: 10px;" class="align-items-center fw-semibold rounded-1 d-flex">
                    <div class="px-2 rounded-1 bg-success">
                      ${totalItems} items
                    </div>
                    <div class="mx-auto">Total</div>
                    <div>
                      ${total} kr
                    </div>
                  </div> 
              </div>
            
        </div>`,
          message: `Your order has been placed. A Standard order completition time is 30 minutes if the order is under four main course items. Further details and confirmation will be emailed to you on <b>${email}</b>.`,
          heading: "Order Placed!",
          type: "success",
        })
      );
      dispatch(cart.clear());
      clearErrors();
    }
  };

  const clearErrors = () => {
    set_nameErr("");
    set_phoneErr("");
    set_emailErr("");
    set_descriptionErr("");
  };

  if (cartItems.length < 1) return <></>;
  return (
    <>
      <Alert fixed={true} />
      <header className="text-center">
        <div className="d-inline-flex flex-wrap py-3">
          <div>
            <img
              src={logo1}
              style={{ width: "70px" }}
              alt="delhi tandoori"
              className="me-2"
            />
          </div>
          <div className="text-end">
            <Logo noHover size={"50px"} colored />
            <small
              style={{
                fontSize: ".65em",
                display: "block",
              }}
              className="fst-italic fw-semibold"
            >
              Same Family - Same Taste
            </small>
          </div>
        </div>
      </header>
      {/* heading */}
      <div
        className="px-4 py-3 d-flex align-items-center"
        style={{ backgroundColor: "var(--color-3)" }}
      >
        <button
          className="btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <i className="fa  fa-chevron-left"></i>
        </button>
        <div className="mx-auto fw-bold fs-5">
          <i className="fa fa-user-lock"></i> Secure Takeaway
        </div>
      </div>

      <div className="container my-5  ">
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "500px" }}
          className=" mx-auto"
        >
          <div className="border-2 border p-4 rounded mb-4">
            <h5 className="text-center my-3 position-relative">
              <div
                style={{
                  height: "2px",
                  zIndex: "-2",
                  backgroundColor: "var(--color-3)",
                }}
                className="w-100 position-absolute top-50 start-50  translate-middle  "
              ></div>
              <span className="bg-white px-2"> Who's the order for</span>
            </h5>
            <div className="my-4">
              <label htmlFor="confirm-order-name" className="fw-semibold">
                Name <span className="text-danger">*</span>
              </label>
              <input
                onChange={() => {
                  set_nameErr("");
                }}
                ref={nameInput}
                placeholder="Enter your name please..."
                type="text"
                id="confirm-order-name"
                className="form-control"
              />
              <div>
                <small className="text-danger">{nameErr}</small>
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="confirm-order-mobile" className="fw-semibold">
                Mobile <span className="text-danger">*</span>
              </label>
              <input
                onChange={() => {
                  set_phoneErr("");
                }}
                ref={phoneInput}
                placeholder="So we can contact you"
                type="tel"
                id="confirm-order-mobile"
                className="form-control"
              />
              <div>
                <small className="text-danger">{phoneErr}</small>
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="confirm-order-email" className="fw-semibold">
                Email Address <span className="text-danger">*</span>
              </label>
              <input
                onChange={() => {
                  set_emailErr("");
                }}
                ref={emailInput}
                placeholder="To send your confirmation"
                type="text"
                id="confirm-order-email"
                className="form-control"
              />
              <div>
                <small className="text-danger">{emailErr}</small>
              </div>
            </div>
            <div className="my-4">
              <label htmlFor="confirm-order-email" className="fw-semibold">
                Comments
              </label>
              <textarea
                onChange={() => {
                  set_descriptionErr("");
                }}
                ref={descriptionInput}
                placeholder="Any special requests?"
                id="confirm-order-description"
                className="form-control"
              ></textarea>
              <div>
                <small className="text-danger">{descriptionErr}</small>
              </div>
            </div>
          </div>

          {submitting ? (
            <div className="w-100 btn custom-btn-green fw-semibold rounded">
              <div
                style={{ height: "1em", width: "1em" }}
                className="spinner-grow text-light me-1"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              <div
                style={{ height: "1em", width: "1em" }}
                className="spinner-grow text-light me-1"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
              <div
                style={{ height: "1em", width: "1em" }}
                className="spinner-grow text-light me-1"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <button className="w-100 btn custom-btn-green fw-semibold rounded">
              Confirm Order
            </button>
          )}
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ConfirmOrder;
